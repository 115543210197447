import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AppContextProvider } from './Components/AppContext';

import HomePage from "./Pages/HomePage"
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import SideCart from "./Components/SideCart";
import Banners from "./Components/Banners";
import Category from "./Pages/Category";
import SignIn from "./Pages/Login";
import ResetPassword from "./Pages/ResetPassword";
import SignUp from "./Pages/Signup";
import Profile from "./Pages/Profile";
import ChangePassword from "./Pages/ChangePassword";
import Cart from "./Pages/Cart";
import Cash from "./Pages/Cash";
import Orders from "./Pages/Orders";
import Order from "./Pages/Order";
import Invoice from "./Pages/Invoice";
import Duplicate from "./Pages/Duplicate";
import SuccessPage from "./Pages/Success";
import ProductPage from "./Pages/ProductPage";
import Ingredients from "./Components/Indegrients";
import SearchProducts from "./Pages/SearchProducts";
import Page from "./Pages/Page";
import ContactForm from "./Pages/Contact";


const App = () => {

  return (
    <>
      <Router>
        <AppContextProvider>
        <Header />
        <Banners />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/Category/:category" element={<Category />} />
          <Route path="/Category/:category/:subcategory" element={<Category />} />
          <Route path="/Login" element={<SignIn />} />
          <Route path="/ResetPassword" element={<ResetPassword />} />
          <Route path="/Signup" element={<SignUp />} />
          <Route path="/Profile" element={<Profile />} />
          <Route path="/ChangePassword" element={<ChangePassword />} />
          <Route path="/Cart" element={<Cart />} />
          <Route path="/Cash" element={<Cash />} />
          <Route path="/Orders" element={<Orders />} />
          <Route path="/Order/:orderid" element={<Order />} />
          <Route path="/Invoice/:orderid" element={<Invoice />} />
          <Route path="/Duplicate/:orderid" element={<Duplicate />} />
          <Route path="/Success" element={<SuccessPage />} />
          <Route path="/Product/:productUrl" element={<ProductPage />} />
          <Route path="/Ingredients" element={<Ingredients />} />
          <Route path="/Search/:query" element={<SearchProducts />} />
          <Route path="/Page/:url" element={<Page />} />
          <Route path="/Contact/:url" element={<ContactForm />} />
        </Routes>
        <SideCart />
        <Footer />
        </AppContextProvider>
      </Router>
    </>
  )
}

export default App;
