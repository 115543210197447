import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../Components/AppContext";
import DeliveryAddress from "../Components/DeliveryAddress";
import DeliverySelect from "../Components/DeliverySelect";
import EditCart from "../Components/EditCart";
import Payment from "../Components/Payment";

const Cash = () => {

    const { customer } = useContext(AppContext);
    const [ component, setComponent ] = useState('A');
    const [ data, setData ] = useState('');
    const [ yishuv, setYishuv] = useState('');
    const navigate = useNavigate();


    useEffect(() => {

        async function Initialize() {

            setData({
                // phoneNo: customer.phoneNo,
                yishuvId: customer.yishuvId,
                address: customer.address,
                entrance: customer.entrance,
                intercom: customer.intercom,
                notes: customer.notes
            });

            console.log(data);
        }

        (customer != null) ? Initialize() : navigate(`/Login`);
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])


    return (

        <div className="container">
            
            <div className="m-5">
                {component === 'A' && <DeliveryAddress data={data} setData={setData} setComponent={setComponent} setYishuv={setYishuv}/>}
                {component === 'B' && <DeliverySelect  data={data} setData={setData} setComponent={setComponent}/>}
                {component === 'C' && <EditCart  data={data} setData={setData} setComponent={setComponent}  yishuv={yishuv}/>}
                {component === 'D' && <Payment data={data} setComponent={setComponent} />}
            </div>

        </div>
    )  
}




export default Cash;
