import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { format } from "date-fns";
import Constants from "../Utilities/Constants";


const DeliverySelect = ({data, setData, setComponent}) => { 
    const [ selectedDate, setSelectedDate] = useState('');
    const [ selectedDay, setSelectedDay] = useState('');
    const [ selectedTime, setSelectedTime] = useState('');
    const [ windows, setWindows ] = useState([]);
    const currentDate = useRef('');

    useEffect(() => {
        const url = `${Constants.API_URL_GET_DELIVERY_WINDOWS}/${data.yishuvId}`;

        const fetchDeliveryWindows = async () => {
            try {    
                const response = await axios.get(url);
                setWindows(response.data);
            }
            catch (error) {
                console.error(error);
            }
        }

        const Initialize = async () => {
            setSelectedDate(data.deliveryDate); 
            setSelectedTime(data.deliveryTime); 
            setSelectedDay(data.deliveryDay);
        }

        Initialize();
        fetchDeliveryWindows();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const handleClick = (window) => {
        setSelectedDate(window.date); 
        setSelectedTime(window.fromTime.substring(0,5) +'-'+window.toTime.substring(0,5)); 
        setSelectedDay(window.day);

        setData(values => ({...values, 
            deliveryDate: window.date,
            deliveryTime: window.fromTime.substring(0,5),
            deliveryDay: window.day
        }));
    }

    const DeliveryButton = ({window}) => {

        return (
            <button className={window.pickup ? 'btn btn-secondary ms-4' : 'btn btn-success ms-4'} onClick={() => handleClick(window)}>
                {window.fromTime.substring(0,5)} - {window.toTime.substring(0,5)} 
            </button> 
        )
    }


    return (
        
        <div className="p-5 m-3 rounded shadow bg-white">
            <div className="d-flex justify-content-between border-bottom border-success">
                <h4 className="">בחר יום ושעה מועדפים למשלוח</h4>
                <h4>
                    <button className="align-left" title="חזור" onClick={()=>setComponent('A')} >{'<<'}</button>
                    {data.deliveryDate &&
                    <button className="align-left" title="הבא" onClick={()=>setComponent('C')} >{'>>'}</button>}
                </h4>
            </div>

            <div className="fw-bold text-success">
                {selectedDate && 
                <span>יום {selectedDay} {format(selectedDate,'dd/MM/yyy')}, {selectedTime}</span>
                }
            </div>
            
            {windows.map((window, index) => {
                if (currentDate.current === window.date) {
                    return (
                        <DeliveryButton window={window} key={index}/>
                    )
                } else {
                    currentDate.current = window.date;
                    return (
                        <>
                        <div className="mt-4 p-1"> 
                            <span>יום {window.day} {format(window.date,'dd/MM/yyy')}</span>
                        </div>
                        <button className="btn btn-success ms-4" value={window.date.substring(0,10) + ' ' + window.fromTime.substring(0,5) }
                            onClick={() => handleClick(window)}>
                            {window.fromTime.substring(0,5)} - {window.toTime.substring(0,5)} 
                        </button>
                        </>
                    )
                }
            })}
            
            <br />
            <br />
            <span className="fw-bold">החלונות באפור הינם חלונות לאיסוף עצמי.</span>
            <br />
            <br />
            {data.deliveryDate &&
            <button to="/Cash" className="btn btn-primary" onClick={()=>setComponent('C')}>המשך לתשלום</button>  
            }

        </div>
    
    )
}

export default DeliverySelect;