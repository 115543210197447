import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import Constants from "../Utilities/Constants";
import { AppContext } from "../Components/AppContext";
import QttyAction from "../Components/QttyAction";
import axios from "axios";


const Cart = () => {
    const { cart } = useContext(AppContext);
    const [ totalCart, setTotalCart ] = useState(0);


    useEffect(()=>{
        var total = cart.reduce((a,v) =>  a = a + v.itemTotal , 0 );
        setTotalCart(total);
    },[cart]);

    return (

        <div className="p-5 m-3 rounded shadow bg-white">
            <div className="d-flex justify-content-between border-bottom border-success">
                <h4 className="">סל הקניות שלי</h4>
            </div>
            <br/>
            <div className="d-flex justify-content-center align-items-center">
                <table className="table table-striped order-items">
                    <thead className="text-center">
                    <tr>
                        <th>תמונה</th>
                        <th >שם המוצר</th>
                        <th>כמות</th>
                        <th>יחידה</th>
                        <th>מחיר</th>
                        <th>סה"כ</th>
                        <th>הערות</th>
                        <th>מחיקה</th>
                    </tr>
                    </thead>
                    <tbody>
                        {cart.map((item) => {
                        return (
                            <CartItem item={item} key={item.itemId} />
                        )
                        })}
                        
                        <tr>
                            <td colSpan="2" >{cart.length} מוצרים בסל</td>
                            <td className="text-start" colSpan="3" >סה"כ סל הקניות:</td>
                            <td className="content" colSpan="3">{totalCart.toFixed(2)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <br/>
            <Link to="/Cash" className="btn btn-primary"> יאללה, לקופה! </Link>  
        </div>

    )
}

const CartItem = ({item}) => {
    
    const { cart, setCart } = useContext(AppContext);

    if (item.product.units.length > 1) 
        {
            const newlist = item.product.units.map((x) => {
                return ((x.unitId === item.unitId) ? {...x, active: "active"} : {...x, active: ""});
            });
            item.product = {...item.product, units: newlist};
        }
    else item.product.units[0].active = 'active';


    const Units = ({units}) => {

        return (
            <div className="unit-types rounded-pill mx-auto text-center">
            { 
                units.length > 1
                    
                    ? units.map((unit, idx) => {
                
                        return (
                            <button key={idx} 
                                className={`float-end rounded-pill units ${unit.active}`}
                                style={{width: `${unit.active === 'active' ? '60%' : '40%' }`}} 
                                onClick={() => toggleUnit(unit.unitId)}>
                                {unit.unitName}
                            </button>
                        )

                    })
                    :  <div>{item.unit.unitName}</div>
            }
            </div>
        )
    }

    const increment = () => {
        var qtty = item.itemQtty + item.unit.unitIncrement;
        updateDB(item.unit.unitId, qtty, item.notes);
    }
    
    const decrement = () => {
        var qtty = item.itemQtty - item.unit.unitIncrement;
        updateDB(item.unit.unitId, qtty, item.notes);
    }

    const updateNotes = (e) => {
        updateDB(item.unit.unitId, item.itemQtty, e.target.value);
    }

    async function updateDB (unitid, qtty, notes) {

        // Update db cart item with new qtty.
        // Receive updated cart item.
        // Update cart list with updated item.

        const apiUpdate = `${Constants.API_URL_UPDATE_CART}/${item.cartId}`;
        const data = {productId: item.productId, unitId: unitid, itemQtty: qtty, notes: notes };
        
        const response = await axios.post(apiUpdate, data);

        (qtty === 0) ? handleRemove(item.id) : handleUpdate(response.data);
    }

    async function handleUpdate(cartitem) {

        // Create new cart list with updated item
        const cartList = cart.map((x) => {
            return ((x.id === cartitem.id) ? cartitem : x);
        })
        
        setCart(cartList);
    }
    
    async function handleRemove(id) {
        
        // Remove item from cart list
        const newList = cart.filter((x) => x.id !== id);
        setCart(newList);
    }

    const toggleUnit = (unitid) => {

        const newlist = item.product.units.map((x,idx) => {
            return ((x.unitId === unitid) ? {...x, active: "active"} : {...x, active: ""});
        });

        const unit = newlist.find((unit) => unit.active === 'active');
        if (Number.isInteger(unit.unitIncrement)) 
        {   
            item.itemQtty = parseInt(item.itemQtty);
        }

        updateDB(unitid, item.itemQtty, item.notes);
    }

    return (
        <tr className="item" key={item.itemId} >
            <td className="image" >
                <Link to={{pathname:`/Product/${item.productId}`}}>
                <img src={`${Constants.SITE_URL}${item.product.image}`} alt={item.product.productName}/>
                </Link>
            </td>
            <td >
                <Link to={{pathname:`/Product/${item.productId}`}}>
                {item.product.productName}
                </Link>
                <div className="notes text-primary">{item.itemNotes}</div>
                {item.product.special && showIngredients(item.selectedIngredients)}
            </td>
            <td >
                <div className="actions">
                    <QttyAction item={item} increment={increment} decrement = {decrement} />
                </div>
            </td>
            <td>
                <div className="actions">
                    <Units units={item.product.units}/>
                </div>
            </td>
            <td >{item.product.price.toFixed(2)}</td>
            <td >{item.itemTotal.toFixed(2)}</td>
            <td className="text-center">
                <input type="text" className="notes" defaultValue={item.itemNotes} 
                    onBlur={updateNotes} />
            </td>
            <td className="text-center">
                <button className="remove" onClick={() => updateDB(item.unitId,0,null)} />
            </td>
        </tr>
    )
}


const showIngredients = (ingredients) => {

    var ings = JSON.parse(ingredients);

    return (
        (ings != null) ?
            ings.map((ing, idx) => {
                return (
                    <div key={idx} className="text-success">{ing.ingredientName}</div>
                )
            })
        : ''
    )
}


export default Cart;