import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import Constants from "../Utilities/Constants";
import Product from "../Components/Product";


const Category = () => {
    
    const [ gallery, setGallery] = useState([]);
    const [ products, setProducts] = useState([])
    const { category, subcategory } = useParams();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {

        setIsLoading(true);
        fetchGallery();
        fetchProducts();
        setIsLoading(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [category, subcategory])

    if (isLoading) {
        return <div>Loading...</div>;
    }
    
    const fetchGallery = async () => {
        
        var url = `${Constants.API_URL_GET_GALLERY}/פרסום-צד`;
            
        try {
            const response = await axios.get(url);
            setGallery(response.data);
        }
        catch(error) {
            alert("לא ניתן להתחבר לשרת");
        }
    }

    const fetchProducts = async () => {
        
        var api = (category)
            ? Constants.API_URL_GET_PRODUCTS_BY_CATEGORY
            : Constants.API_URL_GET_PRODUCT;
            
        console.log(category);
        if (category != null) { api = `${api}/${category}` }
        if (subcategory != null) { api = `${api}/${subcategory}` }

        try {
            const response = await axios.get(api);
            setProducts(response.data);
        }
        catch(error) {
            alert("לא נמצאו מוצרים בקטגוריה זו");
        }
    }

    return (
        <div className="container py-4">
            <div className="row">
                <div className="col-md-2 text-center">
                <div className="d-flex flex-wrap justify-content-around w-100">
                {gallery.map((image, key) => {
                    return (
                        <Link to={image.imageLink} className="w-100 p-2" key={key}>
                            <img src={`${Constants.IMAGE_URL}${image.fileName}`}
                            alt={image.imageTitle} />
                                {/* <img src={`${Constants.IMAGE_URL}${image.fileName}`} key={key} alt={image.imageTitle} /> */}
                        </Link>
                    )
                })}
            </div>
                </div>
                <div className="col-md-10">
                <div className="d-flex flex-wrap justify-content-around">
                {products && products.map((product, key) => {
                    return (
                        <Product product={product} key={key} />
                    )
                })}
            </div>

                </div>
            </div>
        </div>
    )
}


export default Category;
