import { useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "./AppContext";
import Constants from "../Utilities/Constants";
import $ from 'jquery';
import axios from "axios";
import QttyAction from "./QttyAction";


const SideCart = () => {
    const { cart } = useContext(AppContext);

    return (
        <>
        <div className="mini-cart" id="mini-cart">

            <button className="close" aria-label="Close" onClick={toggleMiniCart} />

            <div className="cart-top">

                <div className="d-flex justify-content-between align-items-center">
                    <span className="title">סל הקניות שלי</span>                    
                    <br />
                    <div className="carttotal">
                        סה"כ סל:&nbsp;&nbsp;<span id="mini-cart-totalprice">{showTotal(cart)}</span> ₪
                        <br />
                        <span id="mini-cart-totalitems"></span>
                        <span> מוצרים בסל</span>
                    </div>
                </div>

            </div> 

            <div className="sidecart" >
                <div id="items">
                    <ul id="carttable">
                        {cart && cart.map((product, idx) => {
                            return (
                                <CartItem item={product} key={idx} />
                            )
                        })}
                    </ul>
                </div>

                <div className="d-flex justify-content-between align-items-center pt-2" >
                    <Link className="btn btn-success" to={'Cart'} onClick={toggleMiniCart}>עריכת סל הקניות</Link>
                    {/* Check if user logged in */}
                    <Link className="btn btn-success" to={'Cash'} onClick={toggleMiniCart}>מעבר לתשלום</Link>
                </div>
                <br />
            </div> 

        </div>

        <div className="overlay body_overlay" onClick={toggleMiniCart}> </div> 
        </>
    )
}


const CartItem = ({item}) => {
    
    const { cart, setCart } = useContext(AppContext);

    if (item.product.units.length > 1) 
        {
            const newlist = item.product.units.map((x) => {
                return ((x.unitId === item.unitId) ? {...x, active: "active"} : {...x, active: ""});
            });
            item.product = {...item.product, units: newlist};
        }
    else item.product.units[0].active = 'active';


    const Action = () => {
        
        return (
            <div className="actions">

                <QttyAction item={item} increment={increment} decrement = {decrement} />
                <Units units={item.product.units}/>

                <div className="ItemNotes">
                    <input type="text" className="notes" placeholder="הערות" defaultValue={item.itemNotes} 
                    onBlur={updateNotes} />
                </div>
            </div>
        )
    }

    const Units = ({units}) => {

        return (
            <div className="unit-types rounded-pill">
            { 
                units.length > 1
                    
                    ? units.map((unit, idx) => {
                
                        return (
                            <button key={idx} 
                                className={`float-end rounded-pill units ${unit.active}`}
                                style={{width: `${unit.active === 'active' ? '60%' : '40%' }`}} 
                                onClick={() => toggleUnit(unit.unitId)}>
                                {unit.unitName}
                            </button>
                        )

                    })
                    :  <div className="units rounded-pill active">{item.unit.unitName}</div>
            }
            </div>
        )
    }

    const increment = () => {
        updateDB(item.unit.unitId, item.itemQtty + item.unit.unitIncrement, item.notes);
    }
    
    const decrement = () => {
        updateDB(item.unit.unitId, item.itemQtty - item.unit.unitIncrement, item.notes);
    }

    const updateNotes = (e) => {
        updateDB(item.unit.unitId, item.itemQtty, e.target.value);
    }

    async function updateDB (unitid, qtty, notes) {

        // Update db cart item with new qtty.
        // Receive updated cart item.
        // Update cart list with updated item.

        const apiUpdate = `${Constants.API_URL_UPDATE_CART}/${item.cartId}`;

        const data = {productId: item.productId, unitId: unitid, itemQtty: qtty, notes: notes };
        
        const response = await axios.post(apiUpdate, data);

        (qtty === 0) ? handleRemove(item.id) : handleUpdate(response.data);
    }

    async function handleUpdate(cartitem) {

        // Create new cart list with updated item
        const cartList = cart.map((x) => {
            return ((x.id === cartitem.id) ? cartitem : x);
        })
        
        setCart(cartList);
    }
    
    async function handleRemove(id) {
        
        // Remove item from cart list
        const newList = cart.filter((x) => x.id !== id);
        setCart(newList);
    }

    const toggleUnit = (unitid) => {

        const newlist = item.product.units.map((x,idx) => {
            return ((x.unitId === unitid) ? {...x, active: "active"} : {...x, active: ""});
        });

        const unit = newlist.find((unit) => unit.active === 'active');
        if (Number.isInteger(unit.unitIncrement)) 
        {   
            item.itemQtty = parseInt(item.itemQtty);
        }

        updateDB(unitid, item.itemQtty, item.notes);
    }

    return (
        <li id={item.id} className="item" key={item.id}>
            <div className="item">
                <div className="thumb"><img src={`${Constants.SITE_URL}${item.product.image}`} alt={item.productName}/></div>

                <div className="content">
                    <div>{item.product.productName}</div>
                    <span className="qtty">{item.itemQtty} {item.unit.unitName}</span>
                </div>

                <span className="total">{item.itemTotal} ₪</span>

                <span className="notes">{item.itemNotes}</span>

                <button className="remove" onClick={() => updateDB(item.unitId,0,null)} />
            </div>

            <Action />
                            
        </li>
    )
}


export function toggleMiniCart() {
    
    if ($('#mini-cart').hasClass("open-cart")) {
        $('#mini-cart').removeClass("open-cart");
        $('body').removeClass("open-cart");
    }
    else {
        $('#mini-cart').addClass("open-cart");
        $('body').addClass("open-cart");
    }
}


export function showTotal(cart) {

    var total = 0;
    var items = 0;

    if (cart) {
        total = (cart.reduce((a,v) =>  a = a + v.itemTotal , 0 )).toFixed(2);
        items = cart.length;
    }

    $('#TotalPrice').text(total);
    // if (items > 0) {$('#TotalPrice').text(cart[0].cartId);}
    $('#mini-cart-totalprice').text(total);
    $('#TotalItems').text(items); 
    $('#mini-cart-totalitems').text(items);
}


export default SideCart;
