import React, { useState, useRef, useEffect } from "react";
import Constants from "../Utilities/Constants";
import axios from "axios";


const DeliveryAddress = ({data, setData, setComponent, setYishuv}) => {
    const [inputs, setInputs] = useState({});
    const phoneRef = useRef(null);
    const [yishuvim, setYishuvim] = useState([]);


    useEffect(() => {

        console.log('rendering deliveryaddress', data);

        async function Yishuvim() {
            try {
                const url = Constants.API_URL_YISHUVIM;

                const response = await axios.get(url);
                setYishuvim(response.data);
            }
            catch (error) {
                console.error(error);
            }
        }

        Yishuvim();
        setInputs(data);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]) 

    useEffect(()=>{

        const yishuv = yishuvim.find((e) => e.yishuvId === parseInt(data.yishuvId));
        setYishuv(yishuv);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[yishuvim])

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}));
        setData(values => ({...values, [name]: value}));
    }

    const handleYishuv = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}));
        setData(values => ({...values, [name]: value}));
        console.log(data,data.yishuvId);
    }

    function validateCellular(cellPhone) {
        const regex = /^0(5[012345678]|6[47]){1}(-)?[^0\D]{1}\d{6}$/;
        
        if (cellPhone === '' || regex.test(cellPhone))
        {
            document.getElementById("phoneError").style.display= "none";
            return true;
        } 
        else {
            var field = document.getElementById("phoneError");
            field.style.display= "block";
            phoneRef.current.focus();
            return false;
        }
    }

    // const options = [
    //         yishuvim.map((yishuv) => {
    //         return ({ value: yishuv.yishuvId, label: yishuv.yishuvName})
    //         })
    // ]

    return (
        
        <div className="p-5 m-3 rounded shadow bg-white">

            <div className="d-flex justify-content-between border-bottom border-success">
                <h4 className="">כתובת למשלוח</h4>
                <h4><button className="align-left" title="הבא" onClick={()=>setComponent('B')} >{'>>'}</button></h4>
            </div>

            <br />
            <form method="post">
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <label htmlFor="address" className="form-label">כתובת</label>
                        <input name="address" id="address" className="form-control" onBlur={handleChange} defaultValue={inputs.address}/>
                        <span className="text-danger"></span>
                    </div>

                    <div className="col-md-3 mb-3">
                        <label htmlFor="yishuvId" className="form-label">ישוב</label>
                        <select name="yishuvId" id="yishuvId" className="form-select" 
                        onBlur={handleYishuv} >
                            {
                                yishuvim.map((yishuv) => {
                                return (<option key={yishuv.yishuvId} value={yishuv.yishuvId} selected={yishuv.yishuvId === parseInt(inputs.yishuvId)} >
                                    {yishuv.yishuvName} </option>)
                                })
                            }
                            </select>
                        <span className="text-danger"></span>
                    </div>
                    <div className="col-md-3 mb-3">
                    <label htmlFor="phoneNo" className="form-label">טלפון נוסף</label>
                        <input name="phoneNo" id="phoneNo" className="form-control" ref={phoneRef}
                            onBlur={(e)=>validateCellular(e.target.value) && handleChange} />
                        <span id="phoneError" className="text-danger hidden">נא הקש מספר טלפון תקין</span>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-3 mb-3">
                        <label htmlFor="entrance" className="form-label">כניסה/קומה/דירה</label>
                        <input name="entrance" id="entrance" className="form-control" onBlur={handleChange} defaultValue={inputs.entrance}/>
                        <span className="text-danger hidden"></span>
                    </div>
                    <div className="col-md-3 mb-3">
                        <label htmlFor="intercom" className="form-label">אינטרקום</label>
                        <input name="intercom" id="intercom" className="form-control" onBlur={handleChange} defaultValue={inputs.intercom}/>
                        <span className="text-danger hidden"></span>
                    </div>

                    <div className="col-md-6 mb-3">
                        <label htmlFor="notes" className="form-label">הערות למשלוח</label>
                        <input name="notes" id="notes" className="form-control" onBlur={handleChange} defaultValue={inputs.notes}/>
                    </div>
                </div>
                
                <div className="row">
                    <div className="col-md-12">
                        <span id="submitError" className="text-danger hidden"></span>
                    </div>
                </div>

            </form>
            <br/>
            <button to="/Cash" className="btn btn-primary" onClick={()=>setComponent('B')}>המשך לבחירת מועדי משלוח או איסוף עצמי</button>  
        </div>      

    )
}

export default DeliveryAddress;