import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Constants from "../Utilities/Constants";
import Product from "../Components/Product";
import Popup from "../Components/Popup";


const HomePage = () => {
    
    const [ gallery, setGallery] = useState([]);
    const [ products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const isMounted = useRef(false);

    useEffect(() => {

        if (!isMounted.current) {
            setIsLoading(true);
            fetchHomeGallery();
            fetchProducts();
            setIsLoading(false);
            console.log('reading data');

            return () => {
                isMounted.current = true;
            }
        } 

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (isLoading) {
        return <div>Loading...</div>;
    }
    
    const fetchHomeGallery = async () => {
        
        var url = `${Constants.API_URL_GET_GALLERY}/home`;
            
        try {
            const response = await axios.get(url);
            setGallery(response.data);
        }
        catch(error) {
            alert("לא ניתן להתחבר לשרת");
        }
    }

    const fetchProducts = async () => {
        
        var url = Constants.API_URL_GET_PRODUCT;
            
        try {
            const response = await axios.get(url);
            setProducts(response.data);
        }
        catch(error) {
            alert("לא ניתן להתחבר לשרת");
        }
    }


    return (
        <>
        <div className="container py-4">
            <div className="d-flex flex-wrap justify-content-around w-100">
                {gallery.map((image, key) => {
                    return (
                        <Link to={image.imageLink} className="w-33" key={key}>
                            <div className="p-5 border shadow category-box" 
                            style={{backgroundImage: `url("${Constants.IMAGE_URL}${image.fileName}")`, backgroundSize: 'cover'}}
                            alt={image.imageTitle}>
                                {/* <img src={`${Constants.IMAGE_URL}${image.fileName}`} key={key} alt={image.imageTitle} /> */}
                                <span className="text-center position-absolute category-text">{image.imageTitle}</span>
                            </div>
                        </Link>
                    )
                })}
            </div>
        </div>
        <div className="container py-4">
            <div className="d-flex flex-wrap justify-content-around">
                {products.map((product, key) => {
                    return (
                        <Product product={product} key={key} />
                    )
                })}
            </div>
        </div>
        {/* <Popup furl="פופאפ-דף-הבית" /> */}
        </>
    )
}


export default HomePage;
