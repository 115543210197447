import React, { useState, useContext, useRef, useEffect } from "react";
import { useNavigate  } from "react-router-dom";
import { AppContext } from "../Components/AppContext";
import Constants from "../Utilities/Constants";
import axios from "axios";


const SignUp = () => {

    const [inputs, setInputs] = useState({});
    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');
    
    const { setCustomer, setCartId } = useContext(AppContext);
    const navigate = useNavigate();
    const phoneRef = useRef(null);
    const emailRef = useRef(null);
    const password1Ref = useRef(null);
    const password2Ref = useRef(null);
    const [yishuvim, setYishuvim] = useState([]);

    useEffect(() => {

        async function fetchYishuvim() {
            try {
                const url = Constants.API_URL_YISHUVIM;
    
                await axios.get(url).then(response => {
                    setYishuvim(response.data);    
                })
                    .catch((err)=>console.log(err));
            }
            catch (error) {
                console.error(error);
            }
        }

        fetchYishuvim();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) 


    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
    }

    const handleHash = async (password) => {
        const encoder = new TextEncoder();
        const data = encoder.encode(password);
        const hashBuffer = await crypto.subtle.digest('SHA-256', data);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const sha256Hash = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
        return sha256Hash;
    }

    const handleSubmit = async (event) => {
        
        event.preventDefault();

        if (password1 === '')
        {
            document.getElementById("password1Error").style.display= "block";
            password1Ref.current.focus();
            return false;
        }
        else
            document.getElementById("password1Error").style.display= "none";


        if (password1 !== password2)
        {
            document.getElementById("password2Error").style.display= "block";
            password2Ref.current.focus();
            return false;
        }
        else
            document.getElementById("password2Error").style.display= "none";

        // Check Yishuv is selected
        var yishuv = document.getElementById("yishuvId").value;
        if (yishuv === '') {
            document.getElementById("yishuvError").style.display= "block";
            yishuv.current.focus();
            return false;
        }
        else
        document.getElementById("yishuvError").style.display= "none";


        const hash = await handleHash(password1);
        console.log('hash',hash);
        sendToServer({...inputs, hash})
    }
    
    const sendToServer = async (data) => {

        // submit registration form
        try {
            const url = Constants.API_URL_REGISTER;

            const json = await axios.post(url, data);
            setCustomer(json);
            navigate("/");
        }
        catch (error) {
            if (error.response) {
                document.getElementById("submitError").style.display= "block";
            }
            else {
                console.error(error.massage);
            }
        }
    }
    
    function validateCellular(cellPhone) {
        const regex = /^0(5[012345678]|6[47]){1}(-)?[^0\D]{1}\d{6}$/;
        
        if (cellPhone === '' || regex.test(cellPhone))
        {
            document.getElementById("phoneError").style.display= "none";
            return true;
        } 
        else {
            var field = document.getElementById("phoneError");
            field.style.display= "block";
            phoneRef.current.focus();
            return false;
        }
    }

    function validateEmail(email) {
        const regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
        
        if (email === '' || regex.test(email))
        {
            document.getElementById("emailError").style.display= "none";
            return true;
        } 
        else {
            var field = document.getElementById("emailError");
            field.style.display= "block";
            emailRef.current.focus();
            return false;
        }
    }

    function validatePassword()  {

        if (password2 === '' || password1 === password2) {
            document.getElementById("password2Error").style.display= "none";
            return true;
        }
        else {
            document.getElementById("password2Error").style.display= "block";
            password2Ref.current.focus();
            return false;
        }
    }


    return (
        <>
        <h3 className="m-5 text-center">הרשמה לאתר</h3>

        <div className="login template d-flex justify-content-center align-items-center w-100 row">
            <div className="col-6 p-5 rounded shadow bg-white">
                <form method="post">
                    <div className="row">
                        <div className="invalid-feedback"></div>
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <label htmlFor="firstName" className="form-label">שם פרטי</label>
                                <input name="firstName" id="firstName" className="form-control" onChange={handleChange} />
                            </div>

                            <div className="col-md-6 mb-3">
                                <label htmlFor="lastName" className="form-label">שם משפחה</label>
                                <input name="lastName" id="lastName" className="form-control" onChange={handleChange} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <label htmlFor="phoneNo" className="form-label">טלפון</label>
                                <input name="phoneNo" id="phoneNo" className="form-control" ref={phoneRef}
                                    onChange={handleChange} onBlur={(e)=>validateCellular(e.target.value)} />
                                <span id="phoneError" className="invalid-feedback">נא הקש מספר טלפון תקין</span>
                            </div>

                            <div className="col-md-6 mb-3">
                                <label htmlFor="email" className="form-label">דואר אלקטרוני</label>
                                <input name="email" id="email" className="form-control"  ref={emailRef} 
                                    onChange={handleChange} onBlur={(e)=>validateEmail(e.target.value)} />
                                <span id="emailError" className="invalid-feedback">נא הקש כתובת דואר אלקטרוני תקין</span>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <label htmlFor="password1" className="form-label">סיסמה</label>
                                <input name="password1" type="password" id="password1" className="form-control" ref={password1Ref}
                                    onChange={(e)=>setPassword1(e.target.value)} />
                                <span id="password1Error" className="invalid-feedback">נא הקש סיסמה</span>
                            </div>

                            <div className="col-md-6 mb-3">
                                <label htmlFor="password2" className="form-label">הקש שוב את הסיסמה</label>
                                <input name="password2" type="password" id="password2" className="form-control" ref={password2Ref}
                                    onChange={(e)=>setPassword2(e.target.value)} onBlur={validatePassword}/>
                                <span id="password2Error" className="invalid-feedback">אין התאמה בין הססמאות</span>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <label htmlFor="address" className="form-label">כתובת</label>
                                <input name="address" id="address" className="form-control" onChange={handleChange} />
                                <span className="invalid-feedback"></span>
                            </div>

                            <div className="col-md-6 mb-3">
                                <label htmlFor="yishuvId" className="form-label">ישוב</label>
                                <select name="yishuvId" id="yishuvId" className="form-select" onChange={handleChange} >
                                    <option value="">-- בחר ישוב --</option>
                                    {
                                        yishuvim.map((yishuv) => {
                                        return (<option key={yishuv.yishuvId} value={yishuv.yishuvId}>{yishuv.yishuvName}</option>)
                                        })
                                    }
                                    </select>
                                    <span id="yishuvError" className="invalid-feedback">נא בחר ישוב</span>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <label htmlFor="entrance" className="form-label">כניסה/קומה/דירה</label>
                                <input name="entrance" id="entrance" className="form-control" onChange={handleChange} />
                                <span className="invalid-feedback"></span>
                            </div>
                            <div className="col-md-3 mb-3">
                                <label htmlFor="intercom" className="form-label">אינטרקום</label>
                                <input name="intercom" id="intercom" className="form-control" onChange={handleChange} />
                                <span className="invalid-feedback"></span>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <label htmlFor="notes" className="form-label">הערות לכתובת</label>
                            <input name="notes" id="notes" className="form-control" onChange={handleChange} />
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-md-12">
                            <span id="submitError" className="invalid-feedback">משתמש קיים במערכת</span>
                        </div>
                    </div>

                    <div className="buttons mt-4">
                        <input type="submit" value="שמור" className="btn btn-primary" onClick={handleSubmit} />
                        <a href="#;" asp-area="Admin" asp-action="Index" className="btn btn-secondary">בטל</a>
                    </div>
                </form>
            </div>      
        </div>
        </>
    )
}


export default SignUp;