import React from "react";


const QttyAction = ({item, increment, decrement}) => {
    return (
    <div className="action-buttons d-flex justify-content-center">
        <button type="button" onClick={increment}><i className="bi bi-plus-square" /></button>
        <input onChange={() => null} type="text" id={item.id} className="product_qty" value={item.itemQtty} />
        <button type="button" onClick={decrement}><i className="bi bi-dash-square" /></button>
    </div>
    )
}

export default QttyAction;