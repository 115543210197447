import React, { useState, useEffect } from "react";
import Carousel from 'react-bootstrap/Carousel';
import Constants from "../Utilities/Constants";
import axios from "axios";


const galleryUrl = "באנרים-דף-הבית";

const Banners = () => {
    const [gallery, setGallery] = useState([]);
    
    useEffect(() => {

        getGallery();

    }, []); 


    async function getGallery() {
        const url = `${Constants.API_URL_GET_GALLERY}/${galleryUrl}`;
        
        const response = await axios.get(url);
        setGallery(response.data);
    }


    return (
        <div className="banner">
        <Carousel fade controls={false} indicators={false} >
                {gallery.map((image, index) => {
                    return (
                        <Carousel.Item key={index}>
                            <img src={`${Constants.IMAGE_URL}${image.fileName}`} alt={image.imageTitle}/>
                            <Carousel.Caption>
                                <h1>{image.imageTitle}</h1>
                            </Carousel.Caption>
                        </Carousel.Item>
                    )
                    })}
        </Carousel>
      </div>
    );
  }

  export default Banners;
